import React, { ReactElement } from "react";
import styles from "./ArrowButton.module.scss";
import classNames from "classnames"


interface ArrowButtonProps {
  title: string;
  blue_icon: boolean;
  icon: ReactElement;
  action: string;
}

export default function ArrowButton({ title, icon, blue_icon, action }: ArrowButtonProps) {
  return (
    <a className={classNames(styles.ArrowButton, { [styles['bg-blue']]: blue_icon })} href={action}>
      <p>{title}</p>
      <div className={classNames(styles.Icon, { [styles['bg-icon-blue']]: blue_icon })}>{icon}</div>
    </a>
  );
}
