import React, { useContext } from "react"
import content from './content.json';
import styles from "../../sections/CreateBudget/CreateBudget.module.scss";
import { LanguageContext } from '../../context/LanguageContext.jsx';

const BudgetInstructions = () => {
    const { language } = useContext(LanguageContext);

    return (
        <div className={styles.BudgetText}>
            <h1 className={styles.Title}>
                {content.title[language][0]} <br />
                <span className={styles.HighlightText}>
                    {content.title[language][1]}
                </span>
                {content.title[language][2]}
            </h1>

            <div className={styles.Instructions}>
                <p className={styles.InstructionsLabel}>{content.instructions[language][0]}</p>
                <ol>
                    {content.instructions[language].map((instruction, i) => {
                        if (i > 0) {
                            return (<li key={i}>{instruction}</li>
                            )
                        } else {
                            return false
                        }
                    })}
                </ol>
            </div>
        </div>
    )
}

export default BudgetInstructions;


