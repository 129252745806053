import React from "react";
import styles from "./SimpleButton.module.scss";
import classNames from "classnames";

export default function SimpleButton({ title, target = "_blank", fullWidth, onClick, variant = "contained", size = "default", ...props }) {
  return (
    <button
      {...props}
      onClick={onClick}
      className={
        classNames(styles.SimpleButton,
          {
            [styles.Contained]: variant === "contained",
            [styles.Outlined]: variant === "outlined",
            [styles.LargeSize]: size === "large",
            [styles.DefaultSize]: size === "default",
            [styles.Disabled]: variant === "disabled",
            [styles.fullWidth]: fullWidth,
          })
      }
    >
      <p>{title}</p>
    </button>
  );
}
