import React, { useState, useRef, useEffect } from "react";
import styles from "./StepByStep.module.scss"
import ProgressBar from "../../components/ProgressBar/ProgressBar"
import { useParallax } from "react-scroll-parallax";

const StepByStep = () => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const [step, setStep] = useState("01");

    const parallax = useParallax({
        onEnter: (e) => {
        },
        onExit: (e) => {
        },
        onProgressChange: (e) => {
            setScrollProgress(e);
        }
    });

    useEffect(() => {
        if (scrollProgress <= 0.4) {
            setStep("01")
        }
        if (scrollProgress >= 0.4 && scrollProgress <= 0.6) {
            setStep("02")
        }
        if (scrollProgress >= 0.6 && scrollProgress <= 0.9) {
            setStep("03")
        }
    }, [scrollProgress])

    return (
        <div ref={parallax.ref} className={styles.Section}>
            <div className={styles.SectionSticky}>
                <div className={styles.ProgressBars}>
                    <ProgressBar min={0} max={0.2} val={scrollProgress} />
                    <ProgressBar min={0.2} max={0.4} val={scrollProgress} />
                    <ProgressBar min={0.4} max={0.6} val={scrollProgress} />
                    {/* <ProgressBar min={0.6} max={0.8} val={scrollProgress} />
                    <ProgressBar min={0.8} max={1} val={scrollProgress} /> */}
                </div>
                <p className={styles.StepTitle}>
                    {step === "01" && "1. Faça e aprove seu orçamento online e em tempo real"}
                    {step === "02" && "2. Faça o upload dos materiais e informações de sua campanha para conferência"}
                    {step === "03" && "3. Inicie a produção"}
                </p>
                <div className={styles.GifContainer}>
                    <img src={`/assets/gifs/${step}.gif`} alt="Macbook showing screenshots from platform" />
                    {/* <ScrollyVideo videoPercentage={scrollProgress} style={{ width: "100px" }} src="/assets/demo.mp4" /> */}
                </div>
            </div>
        </div>
    )
}

export default StepByStep;