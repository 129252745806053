import React, { createContext, useEffect, useState } from 'react';

export const BudgetContext = createContext();

export const BudgetProvider = ({ children }) => {
    const [budget, setBudget] = useState([]);
    const [budgetPayload, setBudgetPayload] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const addItemToBudget = (id, item) => {
        var newBudget = [...budget];
        newBudget[id] = item;
        setBudget(newBudget);
    };

    const sumAllPrices = (list, set) => {
        var sum = 0;
        list.forEach(specs => {
            if (specs !== undefined && specs.length > 0) {
                specs.forEach(spec => {
                    sum += spec.total;
                });
            }
        });
        set(sum);
    }

    const createBudgetPayload = (list) => {
        var payload = [];
        list.forEach((format) => {
            if (format !== undefined && format.length > 0) {
                format.forEach((spec) => {
                    const { id, total, ...obj } = spec;
                    payload.push(obj)
                })
            }
        })
        setBudgetPayload({
            items: [...payload]
        })
    }

    useEffect(() => {
        if (budget.length > 0) {
            sumAllPrices(budget, setTotalPrice);
            createBudgetPayload(budget);
        } else {
            setTotalPrice(0);
        }
    }, [budget])

    return (
        <BudgetContext.Provider value={{ budget, addItemToBudget, totalPrice, budgetPayload, setBudget }}>
            {children}
        </BudgetContext.Provider>
    );
};
