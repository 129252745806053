import React, { useState, useContext, useEffect } from 'react';
import './CookieBar.module.scss';
import styles from "./CookieBar.module.scss";
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';
import classNames from 'classnames';

const CookieBar: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { language } = useContext(LanguageContext);

  const handleAccept = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 6000);
  }, [])

  // if (!isVisible) return null;

  return (
    <div className={classNames(styles.CookieBar, { [styles.Appear]: isVisible })}>
      <p>{content.cookieMessage[language][0]}  
        <a href="/">{content.cookieMessage[language][1]} </a> 
      </p>
      <button onClick={handleAccept}>{content.buttonText[language]}</button>
    </div>
  );
};

export default CookieBar;