import React from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { menuClasses } from "@mui/material/Menu";
import { selectClasses } from "@mui/material/Select";
import colors from "../../utils/colors.json"

const SelectComp = ({ label, typeId, setTypeId, options }) => {
    return (
        <FormControl
            sx={{
                flexGrow: 1,
                '@media (max-width: 768px)': {
                    flexGrow: 'initial',
                    width: "60%"
                }
            }}>
            <Typography
                component="p"
                sx={{
                    fontSize: ".9rem",
                    color: colors.noir,
                    fontWeight: 600,
                    marginBottom: "0.5em"
                }}
            >
                {label}
            </Typography >
            <Select
                disableUnderline
                displayEmpty
                variant="standard"
                MenuProps={{
                    anchorOrigin: {
                        vertical: "center",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                    sx: {
                        boxShadow: 0,
                        marginBlock: "0.9rem",
                        [`& .${menuClasses.paper}`]: {
                            borderRadius: "0px 0px 10px 10px",
                            boxShadow: 0,
                            borderColor: colors.noir,
                            border: "1.2px solid",
                            borderTop: 0,
                        },
                        [`& .${menuClasses.list}`]: {
                            paddingTop: 0,
                            paddingBottom: 0,
                            background: "white",
                            "& li": {
                                padding: "12px",
                            },
                            "& li:hover": {
                                background: colors.light_gray,
                            },
                            "& li.Mui-selected": {
                                color: "white",
                                background: colors.noir,
                            },
                            "& li.Mui-selected:hover": {
                                background: colors.noir,
                            },
                        },
                    },
                }}
                IconComponent={ExpandMoreIcon}
                value={typeId}
                onChange={(event) => setTypeId(event.target.value)}
                sx={{
                    borderColor: colors.noir,
                    [`& .${selectClasses.select}`]: {
                        background: "transparent",
                        color: colors.noir,
                        borderRadius: "12px",
                        borderColor: colors.noir,
                        border: "1.5px solid",
                        padding: "10px 16px",
                        "&:focus": {
                            borderRadius: "12px",
                            background: "transparent",
                            borderColor: colors.noir,
                        },
                    },
                    [`& .${selectClasses.icon}`]: {
                        right: "12px",
                    },
                }}
            >
                <MenuItem disabled divider={true} value={""}><i>Selecione...</i></MenuItem>
                {options.map((option, i) => {
                    return <MenuItem key={i} divider={true} value={option.formatId}>{option.type}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default SelectComp;