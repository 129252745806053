import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./AboutVideo.module.scss";
import { Parallax } from 'react-scroll-parallax';
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';

export default function AboutVideo() {
  const { language } = useContext(LanguageContext);
  const parentRef = useRef<HTMLDivElement>(null);
  const [startScroll, setStartScroll] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setStartScroll(rect.top + scrollTop);
    }
  }, []);

  return (
    <div ref={parentRef} className={styles.AboutVideo} id="about-video">
      <div className={styles.AboutSticky}>
        <Parallax startScroll={startScroll}
          endScroll={startScroll + window.innerHeight} scale={[1, 0.8]}>
          <img
            className={styles.Video}
            src="/assets/images/placeholder-interface.png"
            alt="Print da tela de Replica"
          />
          <div className={styles.Text}>
            {content.text[language][0]}
            <br />
            {content.text[language][1]}
            <br />
            {content.text[language][2]}
          </div>
        </Parallax>
      </div>
    </div >
  );
}
