import React from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import colors from "../../utils/colors.json";

export default function TextFieldComp({ label, value, setValue, onChange, fullWidth, ...props }) {

    return (
        <FormControl sx={{
            flexGrow: 1, '@media (max-width: 768px)': {
                flexGrow: 'initial',
                width: fullWidth ? "100%" : "40%"
            }
        }}>
            <Typography
                component="p"
                sx={{
                    fontSize: ".9rem",
                    color: colors.noir,
                    fontWeight: 600,
                    marginBottom: "0.5em"
                }}
            >
                {label}
            </Typography >
            <TextField
                {...props}
                value={value}
                onChange={(event) => onChange ? onChange(event) : setValue(event.target.value)}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        background: "transparent",
                        color: colors.noir,
                        borderRadius: "12px",
                        borderColor: colors.noir,
                        border: "1.5px solid",
                        boxSizing: "border-box",
                        padding: "5px 15px",
                        minHeight: "2.8rem",
                        "&:focus": {
                            borderRadius: "12px",
                            background: "transparent",
                            borderColor: colors.noir,
                        }, "input[type=number]": {
                            mozAppearance: "textfield"
                        },
                        "input[type=number]::-webkit-outer-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0
                        },
                        "input[type=number]::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                            margin: 0
                        },
                    }
                }
                }
                sx={{
                    minWidth: 50,
                    borderColor: colors.noir,
                }}
            />
        </FormControl>
    );
}
