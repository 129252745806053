import React, { useEffect, useState, useContext } from "react";
import styles from "./Header.module.scss";
import ArrowButton from "../../components/ArrowButton/ArrowButton.tsx";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Layers from "../Layers/Layers.tsx";
import Navbar from "../../components/Navbar/Navbar.tsx";
import classNames from "classnames";
import { Parallax } from 'react-scroll-parallax';
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';

export default function Header() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1500);
  }, [])

  return (
    <>
      <Layers />
      <div className={classNames(styles.HeaderContainer, { [styles.visible]: isLoaded })}>
        <Navbar />
        <div className={styles.Header}>
          <div className={styles.HeaderText}>
            <Parallax>
              <h1 className={styles.Title}>{content.title[language][0]}
                <span className={styles.HighlightText}>
                  {content.title[language][1]}
                  <br />
                  {content.title[language][2]}
                </span>
                <br /> <span className={styles.HighlightText}>{content.title[language][3]}</span>
                <br />
                {content.title[language][4]}
              </h1>
            </Parallax>

            <div className={styles.AppearOpacity}>
              <h2 className={styles.Subtitle}>{content.description[language][0]}
                <br /> {content.description[language][1]}
                <br /> {content.description[language][2]}
                <br /> {content.description[language][3]}
              </h2>
              <ArrowButton title={content.button[language]} action={"/#about-video"} icon={<ArrowForwardRoundedIcon fontSize="large" />} blue_icon={false} />
            </div>
          </div>

          <div className={styles.HeaderImg}>
            <img className={styles.Img} src="/assets/images/header-screen.png" alt="Tela de computador com duas peças digitais escrito Replica" />
            <div className={styles.WhiteBlur}></div>
          </div>
        </div>
      </div>
    </>
  );
}
