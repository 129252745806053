import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingBottom: 15,
    transition: 'all 0.5s ease',
    background: 'linear-gradient(0deg, rgba(246, 246, 246, 1) 0%, rgba(227, 232, 234, 1) 100%)'
}));

export default AccordionDetails;
