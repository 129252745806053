import React, { useState } from "react";
import styles from "./CreateBudget.module.scss";
import AllFormats from "../../components/AllFormats/AllFormats.jsx";
import PriceResult from "../../components/PriceResult/PriceResult.jsx";
import { BudgetProvider } from "../../context/BudgetContext.jsx";
import BudgetInstructions from "../../components/BudgetInstructions/BudgetInstructions.jsx";
import QuotationModal from "../../components/QuotationModal/QuotationModal.jsx";

export default function CreateBudget() {
    const [openModalSend, setOpenModalSend] = useState(false);

    return (
        <BudgetProvider>
            <div className={styles.BudgetSection}>
                <BudgetInstructions />
                <div className={styles.FormatsContainer}>
                    <AllFormats />
                    <PriceResult handleOpenModal={() => { setOpenModalSend(true) }} />
                </div>
            </div>
            {openModalSend && <QuotationModal open={openModalSend} handleClose={() => { setOpenModalSend(false); }} />}
        </BudgetProvider>
    )
}