import React, { useEffect, useState } from "react";
import styles from "./Layers.module.scss";
import classNames from "classnames";

export default function Layers() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [endMotion, setEndMotion] = useState(false);

  useEffect(() => {
    setIsLoaded(true);

    setTimeout(() => {
      setEndMotion(true);
    }, 2000);
  }, [])

  return (
    <div className={classNames(styles.Layers, { [styles.endMotion]: endMotion })}>
      <div className={classNames(styles.Layer_1, { [styles.motion]: isLoaded, [styles.endMotion]: endMotion })}></div>
      <div className={classNames(styles.Layer_2, { [styles.motion]: isLoaded, [styles.endMotion]: endMotion })}></div>
      <div className={classNames(styles.Layer_3, { [styles.motion]: isLoaded, [styles.endMotion]: endMotion })}></div>
      <div className={classNames(styles.Layer_4, { [styles.motion]: isLoaded, [styles.endMotion]: endMotion })}></div>
    </div>
  );
}
