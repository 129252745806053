import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../utils/colors.json"

const Bar = styled.div`
    width: 100%;
    height: 3px;
    background-color: ${colors.gray};
    overflow: hidden;
    border-radius: 50px;
`
const BarFill = styled.div`
    transition: width 0.5s ease;
    width:0%;
    height: 100% ;
    background-color: ${colors.white};

    &.loading{
        width: ${props => props.value * 100}%;       
    }
`

const ProgressBar = ({ val, max, min }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (val >= max) {
            setValue(1);
        }
        if (val <= min) {
            setValue(0);
        }
        // if (val > min && val < max) {
        //     setValue(val);
        // }
    }, [val])


    return (
        <Bar>
            <BarFill className={value > 0 && `loading`} value={value}></BarFill>
        </Bar>
    )
}

export default ProgressBar