import React, {useContext} from "react";
import styles from "./Footer.module.scss";
import { ReactComponent as LandscapeLogo } from "../../icons/landscape.svg";
import { LanguageContext } from '../../context/LanguageContext.jsx';
import content from './content.json';

export default function Footer() {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles.Footer}>
      <div className={styles.ContactButton}>
        <div className={styles.Title}> {content.button[language]}</div>
        <div className={styles.Email}> contato@byreplica.io </div>
        <div className={styles.Layer}></div>
      </div>

      <div className={styles.FooterLinks}>
        <a target="_blank" rel="noreferrer" href="https://landscape.productions/" className={styles.Ecossystem}>
          <p>{content.footer_text[language]}</p>
          <LandscapeLogo className={styles.LandscapeLogo} />
        </a>
        <div className={styles.SocialMedia}>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/">instagram</a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/">linkedin</a>
        </div>
      </div>
    </div>
  );
}
