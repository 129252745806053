import React, { useEffect, useRef, useState, useContext } from "react";
import SelectComp from "../SelectComp/SelectComp";
import TextFieldComp from "../TextFieldComp/TextFieldComp.jsx";
import { ListItem, IconButton, } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { LanguageContext } from '../../context/LanguageContext';
import content from "./content.json"

const SpecItem = ({ id, options, spec, specs, setSpecs }) => {
    const [typeId, setTypeId] = useState("");
    const [amount, setAmount] = useState(1);
    const currentSpec = useRef({ ...spec, id: id });
    const { language } = useContext(LanguageContext)

    const removeSpec = (itemId) => {
        const newSpecsList = specs.filter((s) => s.id !== itemId);
        setSpecs(newSpecsList);
    };

    const editItem = (_id, newSpec) => {
        const newSpecsList = [...specs];
        const index = newSpecsList.findIndex(obj => obj.id === _id);
        newSpecsList[index] = newSpec;
        setSpecs(newSpecsList);
    };

    useEffect(() => {
        var totalPrice = 0
        if (typeId !== "") {
            totalPrice = options.find(opt => opt.formatId === typeId).price * amount;
        }

        currentSpec.current = {
            ...currentSpec.current,
            formatId: typeId,
            amount: Number(amount),
            total: totalPrice
        }
        editItem(id, currentSpec.current)
    }, [typeId, amount])

    return (
        <ListItem sx={{ gap: 2, alignItems: "center", justifyContent: "space-between", paddingRight: 1 }}>
            <SelectComp typeId={typeId} setTypeId={setTypeId} options={options} label={content.label_setting[language]} />
            <TextFieldComp type='number' label={content.label_amount[language]} value={amount} setValue={setAmount} />
            <IconButton onClick={() => removeSpec(id)} edge="start" aria-label="delete" sx={{ alignSelf: "end", boxSizing: "border-box", paddingBottom: "10px" }}>
                <DeleteIcon />
            </IconButton>
        </ListItem>
    );
}

export default SpecItem;
